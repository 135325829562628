import { Injectable, Injector, WritableSignal, signal } from '@angular/core';
import { BimService } from 'app/services/bim/bim.service';

@Injectable({
    providedIn: 'root',
})
export class DataTableService extends BimService {
    type: WritableSignal<string> = signal('edit');

    constructor(injector: Injector) {
        super(injector);
    }
}
